$(function(){
    $('[data-moveTo]').on('click', function(){
        var speed = 500;
        var href= $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - $('header').height();
        $('html, body').animate({scrollTop:position}, speed, 'swing');
        return false;
    });
    
    
});
$(window).on('load', function() {
    var target = location.hash;
    if (target) {
        
      let position = $(target).offset().top - $('.p-header').height();
      $('html, body').animate({ scrollTop: position }, 100);
    }
});